@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-sortable-tree/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

@import 'components/kit/core/style'; // Clean UI KIT styles
@import 'components/cleanui/styles/style'; // Clean UI styles
@import 'components/app/style/filter'; // Filter UI styles
@import 'components/app/style/table'; // Table UI styles
@import 'components/app/style/badge'; // Badge UI styles
@import 'components/app/style/banner'; // Banner UI styles

// TODO: Later take in css modules

.ant-switch {
  background-color: #ff4d4f !important;
}
.ant-switch-checked {
  background-color: #4b7cf3 !important;
}

.cursor-pointer {
  cursor: pointer;
}
