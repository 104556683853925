.text-ele {
  white-space: normal;
  max-height: 32px;
  overflow: hidden;
  display: flex;
  line-height: 1.2em;
  word-break: break-word;

  &.center {
    align-items: center;
  }

  &.right {
    justify-content: flex-end;
  }
}

.ant-table-content {
  overflow: auto;

  table {
    min-width: 900px;
  }
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 20px 0;
  margin: 0 -10px;
  .filter-field {
    padding: 10px;
    min-width: 80px;
    max-width: 360px;

    @media (max-width: 1500px) {
      max-width: 250px;
    }

    &.field-xl {
      width: 420px;
      max-width: 420px;
      @media (max-width: 1500px) {
        width: 380px;
        max-width: 380px;
      }
    }

    &.field-lg {
      width: 360px;
      max-width: 360px;
      @media (max-width: 1500px) {
        width: 320px;
        max-width: 320px;
      }
    }

    &.field-sm {
      width: 180px;
      @media (max-width: 1500px) {
        width: 140px;
      }
    }
  }


}

.text-del {
  text-decoration: line-through;
}

a.text-del:hover {
  text-decoration: line-through;
}
